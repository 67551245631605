var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Form',{attrs:{"title":_vm.title,"loading":_vm.loading,"hide-reset":_vm.edit},on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('v-row',[_c('FormItem',{attrs:{"name":"Testo","rules":{ required: true },"md":4,"lg":4,"xl":4},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('FormItem',{attrs:{"name":"URL (HTTPS)","rules":{
        required: true,
        url: true,
        https: true,
      },"md":4,"lg":4,"xl":4},model:{value:(_vm.href),callback:function ($$v) {_vm.href=$$v},expression:"href"}}),_c('FormItem',{attrs:{"type":"select","values":_vm.targets,"name":"Apre","md":4,"lg":4,"xl":4},model:{value:(_vm.target),callback:function ($$v) {_vm.target=$$v},expression:"target"}}),_c('FormItem',{attrs:{"name":"URL immagine","rules":{
        required: false,
        url: true,
        https: true,
      },"md":4,"lg":4,"xl":4},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('FormCustomers',{attrs:{"customer_id":_vm.customer_id,"name":"Limita a Cliente","hint":"Ricerca per ragione sociale o Partita IVA","persistent-hint":"","clearable":"","md":4,"lg":4,"xl":4},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }