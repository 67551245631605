import { isObject, isFunction, get } from 'lodash'

export const flattenTree = (tree = [], key = 'children', flattened = []) => {
  tree.forEach((treeItem) => {
    flattened.push(treeItem)
    if (treeItem[key]) {
      flattenTree(treeItem[key], key, flattened)
    }
  })
  return flattened
}

/*
Applies a filtering function to deeply nested objects
!! DO NOT EVER PERFORM SIDE EFFECTS AS FN IS RUN AN INDEFINITE AMOUNT OF TIMES !!
const a = [
  {  value: true  },
  {  children: [  {   value: false  }  ]  },
  {  children: [  {   children: [
                    {  value: false  },
                    {  children: [  {  value: true  }  ]  }  ]  }  ]  }  ]
deepFilter(a, item => item.value, 'children') WILL PRODUCE:
[{"value": true}, {"children": [{"children": [{"children": [{"value": true}]}]}]}]
*/

export const deepFilter = (arr, fn, key = 'items', parent = {}) => {
  return arr.reduce((acc, item) => {
    // Non nested object are checked straight away
    if (!item[key]) {
      if (fn(item)) {
        acc.push(item)
      }
    } else {
      // Add the obj only if any of the children will pass the test
      if (deepFilter(item[key], fn, key, item).length)
        acc.push({ ...item, [key]: deepFilter(item[key], fn, key) })
    }
    return acc
  }, [])
}

export const finalize = (item, key = 'text', target) => {
  let finalized = null
  if (isObject(item)) {
    if (isFunction(item[key])) {
      finalized = item[key](target)
    } else {
      finalized = item[key]
    }
  } else finalized = item
  return finalized
}

/**
 * Used with vuetify custom filter.
 * Passed an array of property names will return a function
 * that will check if any of those prop corresponds
 * to the query test
 */
export const customFilterFromProps = (props) => (item, queryText) => {
  const safeText = (queryText || '').toLowerCase()
  return props
    .map((prop) => (get(item, prop) || '').toLowerCase())
    .some((prop) => prop.includes(safeText))
}
