<template>
  <Form
    :title="title"
    :loading="loading"
    @submit="onSubmit"
    @reset="onReset"
    :hide-reset="edit"
  >
    <v-row>
      <FormItem
        v-model="text"
        name="Testo"
        :rules="{ required: true }"
        :md="4"
        :lg="4"
        :xl="4"
      />

      <FormItem
        v-model="href"
        name="URL (HTTPS)"
        :rules="{
          required: true,
          url: true,
          https: true,
        }"
        :md="4"
        :lg="4"
        :xl="4"
      />

      <FormItem
        v-model="target"
        type="select"
        :values="targets"
        name="Apre"
        :md="4"
        :lg="4"
        :xl="4"
      />
      <FormItem
        v-model="image"
        name="URL immagine"
        :rules="{
          required: false,
          url: true,
          https: true,
        }"
        :md="4"
        :lg="4"
        :xl="4"
      />

      <FormCustomers
        v-model="customer_id"
        :customer_id="customer_id"
        name="Limita a Cliente"
        hint="Ricerca per ragione sociale o Partita IVA"
        persistent-hint
        clearable
        :md="4"
        :lg="4"
        :xl="4"
      />
    </v-row>
  </Form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapMutations, mapState } from 'vuex'
import { authComputed } from '@state/helpers.js'

import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormCustomers from '@components/forms/FormCustomers.vue'

const { mapFields } = createHelpers({
  getterType: 'navEntries/getCurrent',
  mutationType: 'navEntries/SET_CURRENT_FIELDS',
})

export default {
  name: 'CustomersForm',
  components: {
    FormItem,
    Form,
    FormCustomers,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
    onsubmitted: {
      type: Function,
    },
  },
  computed: {
    ...authComputed,
    ...mapFields([
      'text',
      'href',
      'target',
      'image',
      'customer_id',
      'customer_location_id',
    ]),
    ...mapState('navEntries', ['targets', 'loading']),
  },
  methods: {
    ...mapMutations('navEntries', {
      reset: 'RESET_CURRENT',
      setCurrentValues: 'SET_CURRENT',
    }),
    ...mapActions('navEntries', ['create', 'update']),
    async onSubmit() {
      try {
        this.$emit('submit')
        let data
        if (!this.edit) {
          data = await this.create()
        } else {
          data = await this.update()
        }

        this.onsubmitted
          ? this.onsubmitted(data.id)
          : this.$emit('submitted', data.id)
      } catch (err) {
        console.log('errore', err)
      }
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
  },
}
</script>

<style scoped></style>
