<template>
  <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <ValidationProvider v-slot="{ errors }" :name="name" :rules="rules">
      <v-autocomplete
        v-model="select"
        :chips="multi"
        :clearable="clearable"
        :deletable-chips="multi"
        :dense="dense"
        :disabled="disabled"
        :error-messages="errors"
        :filter="customFilter"
        :hint="hint"
        :items="items"
        :label="label || name"
        :loading="loading"
        :multiple="multi"
        :persistent-hint="persistentHint"
        :readonly="readonly"
        :search-input.sync="search"
        :small-chips="multi"
        :return-object="returnObject"
        hide-details
        filled
        @input="onInput"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              Non è stato trovato nessun cliente.
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-avatar
            color="indigo"
            class="headline font-weight-light white--text"
          >
            {{ item.text.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.text" />
            <v-list-item-subtitle v-text="item.piva_cfisc" />
          </v-list-item-content>
        </template>
      </v-autocomplete> </ValidationProvider
  ></v-col>
</template>

<script>
/* eslint-disable camelcase */
import { mapActions, mapMutations, mapState } from 'vuex'
import { debounce } from 'lodash'
import { customFilterFromProps } from '@/src/utils/helper-functions'

export default {
  name: 'FormCustomers',
  components: {},
  props: {
    chips: {
      default: false,
      type: Boolean,
    },
    clearable: {
      default: false,
      type: Boolean,
    },
    customer_id: {
      default: null,
      type: [String, Array],
    },
    deletableChips: {
      default: false,
      type: Boolean,
    },
    dense: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hint: {
      default: null,
      required: false,
      type: String,
    },
    label: {
      default: null,
      required: false,
      type: String,
    },
    cols: {
      default: 12,
      type: [String, Number],
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
    md: {
      default: 3,
      type: [String, Number],
    },
    lg: {
      default: 3,
      type: [String, Number],
    },
    xl: {
      default: 3,
      type: [String, Number],
    },
    multi: {
      default: false,
      type: Boolean,
    },
    name: {
      default: 'Clienti',
      type: String,
    },
    persistentHint: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    returnObject: {
      default: false,
      type: Boolean,
    },
    rules: {
      default: '',
      required: false,
      type: [String, Object],
    },
    value: {
      default: null,
      type: [String, Array],
    },
  },
  data: () => ({
    loading: false,
    search: null,
    select: null,
  }),
  computed: {
    ...mapState('customers', {
      items: (state) => state.list,
    }),
  },
  watch: {
    customer_id: {
      immediate: true,
      handler: 'prefill',
    },
    search(val) {
      val && val !== this.select && this.querySelections(val)
      !val && this.querySelections(null)
    },
    /** When value is reset from the outside blank everything  */
    value(val) {
      if (!val) {
        this.select = this.multi ? [] : null
        this.search = null
        this.clearList()
      }
    },
  },
  async mounted() {
    await this.searchCustomers({})
  },
  beforeDestroy() {
    this.clearList()
  },
  methods: {
    ...mapActions('customers', {
      searchCustomers: 'getDropdownList',
    }),
    ...mapMutations('customers', {
      clearList: 'RESET_STATS_DATA',
    }),
    async prefill() {
      if (this.customer_id) {
        this.loading = true
        await this.searchCustomers({
          filters: {
            id: this.customer_id,
          },
        })
        this.select = this.customer_id
        this.loading = false
      } else {
        this.clearList()
        this.select = this.multi ? [] : null
      }
    },
    customFilter: customFilterFromProps(['text', 'piva_cfisc']),

    async querySelections(query) {
      this.loading = true
      await this.searchCustomers({
        filters: {
          query,
        },
      })
      this.loading = false
    },
    onInput: debounce(function(value) {
      this.$emit('input', value)
    }, 250),
  },
}
</script>
